html {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: 'Rogan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3f3f3f !important;
  width: 100%;
  min-height: 100%;
  overscroll-behavior: none;
}

code {
  font-family: 'Roboto Mono', monospace;
}

#underlay {
  height: var(--height);
  width: var(--width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  overflow: hidden;
  overscroll-behavior: none;
}

#primary-viewer-container {
  height: var(--height);
  width: var(--width);
  display: none;
  overscroll-behavior: none;
  overflow: hidden;
}

#primary-viewer {
  height: 100%;
  width: 100%;
  --poster-color: transparent;
  overscroll-behavior: none;
  overflow: hidden;
  user-select: none;
}

.floating {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@keyframes action {
  0% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #262625;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6e7c83;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #edf2f4;
}
